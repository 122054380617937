@tailwind base;
@tailwind components;
@tailwind utilities;


/* .google-login-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 999px;
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.google-login-button:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.google-login-button img {
  margin-right: 8px;
  height: 20px;
} */

.google-login-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 999px;
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.google-login-button:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.google-login-button img {
  margin-right: 8px;
  height: 20px; 
}


@keyframes fade-in-up {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in-up {
  animation: fade-in-up 0.5s ease-out forwards;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.notification-modal {
  width: 80vw !important;
  max-width: 80vw !important;
}

@media (min-width: 768px) {
  .notification-modal {
    width: 30vw !important;
    max-width: 30vw !important;
  }
}


/* For Webkit browsers (Chrome, Safari) */



/* Apply custom scrollbar to all elements */
/* * {
  scrollbar-width: thin; 
  scrollbar-color: blue transparent; 
} */

/* For WebKit browsers (Chrome, Safari, Edge) */
*::-webkit-scrollbar {
  width: 4px; /* Thinner scrollbar width */
  height: 4px;
  scrollbar-width: thin; 
}

*::-webkit-scrollbar-track {
  background: transparent; /* Transparent track */
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(124, 124, 181); /* Blue scrollbar thumb */
  border-radius: 10px; /* Rounded scrollbar thumb */
}

*::-webkit-scrollbar-thumb:hover {
  background-color: rgb(111, 111, 188); /* Darker blue on hover */
}


.file-viewer-container {
  width: 600px;
  height: 400px;
  border: 1px solid #ccc;
  position: relative;
  background-color: #f9f9f9;
  overflow: auto;
}

/* // <uniquifier>: Use a unique and descriptive class name
// <weight>: Use a value from 100 to 900 */

.raleway-regular {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.roboto-thin {
  font-family: "Roboto", system-ui;
  font-weight: 100;
  font-style: normal;
}

.roboto-light {
  font-family: "Roboto", system-ui;
  font-weight: 300;
  font-style: normal;
}

.roboto-regular {
  font-family: "Roboto", system-ui;
  font-weight: 400;
  font-style: normal;
}

.roboto-medium {
  font-family: "Roboto", system-ui;
  font-weight: 500;
  font-style: normal;
}

.roboto-bold {
  font-family: "Roboto", system-ui;
  font-weight: 700;
  font-style: normal;
}

.roboto-black {
  font-family: "Roboto", system-ui;
  font-weight: 900;
  font-style: normal;
}

.roboto-thin-italic {
  font-family: "Roboto", system-ui;
  font-weight: 100;
  font-style: italic;
}

.roboto-light-italic {
  font-family: "Roboto", system-ui;
  font-weight: 300;
  font-style: italic;
}

.roboto-regular-italic {
  font-family: "Roboto", system-ui;
  font-weight: 400;
  font-style: italic;
}

.roboto-medium-italic {
  font-family: "Roboto", system-ui;
  font-weight: 500;
  font-style: italic;
}

.roboto-bold-italic {
  font-family: "Roboto", system-ui;
  font-weight: 700;
  font-style: italic;
}

.roboto-black-italic {
  font-family: "Roboto", system-ui;
  font-weight: 900;
  font-style: italic;
}


.lato-thin {
  font-family: "Lato", system-ui;
  font-weight: 100;
  font-style: normal;
}

.lato-light {
  font-family: "Lato", system-ui;
  font-weight: 300;
  font-style: normal;
}

.lato-regular {
  font-family: "Lato", system-ui;
  font-weight: 400;
  font-style: normal;
}

.lato-bold {
  font-family: "Lato", system-ui;
  font-weight: 700;
  font-style: normal;
}

.lato-black {
  font-family: "Lato", system-ui;
  font-weight: 900;
  font-style: normal;
}

.lato-thin-italic {
  font-family: "Lato", system-ui;
  font-weight: 100;
  font-style: italic;
}

.lato-light-italic {
  font-family: "Lato", system-ui;
  font-weight: 300;
  font-style: italic;
}

.lato-regular-italic {
  font-family: "Lato", system-ui;
  font-weight: 400;
  font-style: italic;
}

.lato-bold-italic {
  font-family: "Lato", system-ui;
  font-weight: 700;
  font-style: italic;
}

.lato-black-italic {
  font-family: "Lato", system-ui;
  font-weight: 900;
  font-style: italic;
}

.ReactModal__Overlay {
  backdrop-filter: none !important;
}
.modal-content-container * {
  opacity: 1 !important;
  filter: none !important;
  backdrop-filter: none !important;
}
